// import './App.css';
// import Companies from './Components/Companies/Companies';
// import Contact from './Components/Contact/Contact';
// import Extra from './Components/Extra/Extra';
// import Footer from './Components/Footer/Footer';
// import GetStarted from './Components/GetStarted/GetStarted';
// import Header from './Components/Header/Header';
// import Residencies from './Components/Residencies/Residencies';
// import Value from './Components/Value/Value';

// function App() {
//   return (
//     <div className="App">
//       <div>
//           <Header/>
//           <Extra/>
//       </div>
//       <Companies/>
//       <Residencies/>
//       <Value/>
//       <Contact/>
//       <GetStarted/>
//       <Footer/>
//     </div>
//   );
// }

// export default App;


import "./App.css";
import Companies from "./Components/Companies/Companies";
import Contact from "./Components/Contact/Contact";
import Extra from "./Components/Extra/Extra";
import Footer from "./Components/Footer/Footer";
import GetStarted from "./Components/GetStarted/GetStarted";
import Header from "./Components/Header/Header";
import Residencies from "./Components/Residencies/Residencies";
import Value from "./Components/Value/Value";

function App() {
  return (
    <div className="App">
      <div className="home">
        <Header />
        <Extra />
      </div>
      <Companies />
      <Residencies />
      <Value />
      <Contact />
      <GetStarted />
      <Footer />
    </div>
  );
}

export default App;