// import React from 'react';
// import './Companies.css';

// const Companies = () => {
//   return (
//     <section className="c-wrapper">
//         <div className="paddings innerWidth flexCenter c-container">
//             <img src="./equinix.png" alt="Equinix logo" />
//             <img src="./prologis.png" alt="Prologis logo" />
//             <img src="./realty.png" alt="Realty logo" />
//             <img src="./tower.png" alt="Tower logo" />
//         </div>
//     </section>
//   )
// }

// export default Companies


import React from "react";
import "./Companies.css";

const Companies = () => {
  return (
    <section className="c-wrapper">
      <h3 className="primaryText heading"> Our Clients </h3>
      <div className="paddings innerWidth flexCenter c-container">
        <img src="./equinix.png" alt="" />
        <img src="./prologis.png" alt="" />
        <img src="./realty.png" alt="" />
        <img src="./tower.png" alt="" />
      </div>
    </section>
  );
};

export default Companies;