// import React from 'react';
// import './Contact.css';
// import {MdCall} from 'react-icons/md';
// import {HiChatBubbleBottomCenter} from 'react-icons/hi2';
// import {BsFillChatDotsFill} from 'react-icons/bs';

// const Contact = () => {
//   return (
//     <section className="c-wrapper">
//         <div className="paddings innerWidth flexCenter c-container">
//             {/* Left side  */}
//             <div className="flexColStart c-left">
//                 <span className='orangeText'>Our Contacts</span>
//                 <span className='primaryText'>Easy to Contact Us</span>
//                 <span className='secondaryText'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, similique mollitia veritatis officia quasi libero minus obcaecati tempore dolore quas sit commodi iure. Quaerat architecto, iusto reprehenderit odio dignissimos quam nesciunt ut laboriosam distinctio eaque nemo.</span>
//                 <div className="flexColStart contactModes">
//                     {/* First row */}
//                     <div className="flexColStart row">
//                         <div className="flexColCenter mode">
//                             <div className="flexStart">
//                                 <div className="flexCenter icon">
//                                     <MdCall size={25}/>
//                                 </div>
//                                 <div className="flexColStart detail">
//                                     <span className='primaryText'>Call</span>
//                                     <span className='secondaryText'>+91 1234567896</span>
//                                 </div>
//                             </div>

//                             <div className="flexCenter button">Call Now</div>
//                         </div>

//                         {/* Second row */}
//                         <div className="flexColStart mode">
//                             <div className="flexStart">
//                                 <div className="flexCenter icon">
//                                     <BsFillChatDotsFill size={25}/>
//                                 </div>
//                                 <div className="flexColCenter detail">
//                                     <span className='primaryText'>Chat</span>
//                                     <span className='secondaryText'>+91 1234567896</span>
//                                 </div>
//                             </div>

//                             <div className="flexCenter button">Chat Now</div>
//                         </div>

//                         {/* Second mode */}
//                         <div className="flexColCenter mode">
//                             <div className="flexStart">
//                                 <div className="flexCenter icon">
//                                     <HiChatBubbleBottomCenter size={25}/>
//                                 </div>
//                                 <div className="flexColStart detail">
//                                     <span className='primaryText'>Video Call</span>
//                                     <span className='secondaryText'>+91 1234567896</span>
//                                 </div>
//                             </div>

//                             <div className="flexCenter button">Call Now</div>
//                         </div>

//                     </div>
//                 </div>
//             </div>

//             {/* Right side */}
//             <div className="c-right">
//                 <div className="image-container">
//                     <img src="./main4.jpg" alt="" />
//                 </div>
//             </div>
//         </div>
//     </section>
//   )
// }

// export default Contact

import React from "react";
import "./Contact.css";
import { MdCall } from "react-icons/md";
import { HiChatBubbleBottomCenter } from "react-icons/hi2";
import { BsFillChatDotsFill } from "react-icons/bs";

const Contact = () => {
  return (
    <div id="contact-us" className="c-wrapper">
      <div className="paddings innerWidth flexCenter c-container">
        {/* left side */}
        <div className="flexColStart c-left">
          <span className="orangeText">Our Contact</span>
          <span className="primaryText">Easy to contact us</span>
          <span className="secondaryText">
            We always ready to help by providing the best services for you. We
            believe a good blace to live can make your life better{" "}
          </span>

          <div className="flexColStart contactModes">
            {/* first row */}
            <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <MdCall size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Call</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Call now</div>
              </div>

              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Chat</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Chat now</div>
              </div>
            </div>

            {/* second row */}
            <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Video Call</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Video Call now</div>
              </div>

              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <HiChatBubbleBottomCenter size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Message</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Message now</div>
              </div>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className="flexEnd c-right">
          <div className="image-container">
            <img src="./main4.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
